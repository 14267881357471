import React from 'react'

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import logo from '../images/logo.png';
import { HashLink } from "react-router-hash-link";
// import NavDropdown from "react-bootstrap/NavDropdown";
import "../css/Navbar.css";
// import Logo from "../Img/Logo.png";
// import $ from 'jquery';
const Navbar1 = () => {
  return (
    <>
       <header id="header" className="header-main rsAbsoluteEl d-lg-block d-none">
  {/* top-header */}
  <div className="mad_top_header">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="nav-logo">
          <Link to="/" className="logo">
            <img src={logo} alt="" />
          </Link>
          </div>
          
        </div>
        <div className="col-md-8">
          <div className="header_info_section">
            <div className="head_socials">
              <p className="icon_location">Vibrant look ,  Andheri west, Mumbai-400053</p>
              {/* <div className="social_icon_list">
                <ul>
                  <li>
                    <a href="#" className="soc_icon icon-facebook" />
                  </li>
                  <li>
                    <a href="#" className="soc_icon icon-twitter" />
                  </li>
                  <li>
                    <a href="#" className="soc_icon icon-gplus" />
                  </li>
                  <li>
                    <a href="#" className="soc_icon icon-tumblr" />
                  </li>
                  <li>
                    <a href="#" className="soc_icon icon-instagram" />
                  </li>
                  <li>
                    <a href="#" className="soc_icon icon-pinterest" />
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="head_phone">
              <div>
                <p className="icon_mobile">+91 996 703 6217</p>
              </div>
              {/* <a href="appointment_page.html" className="mad_button">
                Make an Appointment
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*main menu*/}
      {/* </Container> */}
  {/* <div className="menu_holder">
    <div className="container"> */}
      <div
        className="menu_wrap"
        datasqstyle='{"top":null}'
        datasquuid="f7ebed50-c366-434d-bfa3-dc26273569bd"
        datasqtop={40}
        style={{ top: 40 }}
      >
        {/* - - - - - - - - - - - - - Navigation - - - - - - - - - - - - - - - - */}

        <div className="nav_item">
          <button id="responsive-nav-button"  className="responsive-nav-button"/>
          <nav id="main_navigation" className="main_navigation">
            <ul id="menu" className="clearfix">
              <li className="current">
              <Link to="/">Home</Link>
              </li>
              <li className="drop">
              <Link to="/non-surgical-hair-replacement">About Us</Link>
                {/*sub menu*/}
               
              </li>
              
              {/* <li className="drop">
                <a href="#">Features</a>
               
                <div className="sub_menu_wrap clearfix">
                  <ul>
                    <li>
                      <a href="featured_elements.html">Elements</a>
                    </li>
                    <li>
                      <a href="featured_typography.html">Typography</a>
                    </li>
                    <li>
                      <a href="featured_columns.html">Columns</a>
                    </li>
                  </ul>
                </div>
              </li> */}
              <li className="drop">
              <Link to="/hair-wigs-for-women-in-andheri">Products</Link>
                {/*sub menu*/}
              
              </li>
             
             
              <li>
              <Link to="/hair-wigs-for-men-in-andheri">Contact Us</Link>
              </li>
            </ul>
          </nav>
        </div>
     
        {/* - - - - - - - - - - - - end Navigation - - - - - - - - - - - - - - - */}
     
    </div>
  

</header>
<Navbar  expand="lg d-lg-none d-block " collapseOnSelect>
      <Container>
        <Navbar.Brand href="/" className="logo"><Link to="/" >
          <img className="logo-two" src={logo}  alt="logo"/>
          </Link> </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav d-flex justify-space-between" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <Nav.Link className="" href="/"><Link to="/" className="nav-link">Home</Link>
                
                </Nav.Link>
                <Nav.Link href="/non-surgical-hair-replacement"><Link to="/non-surgical-hair-replacement" className="nav-link">About</Link></Nav.Link>
                <Nav.Link href="/hair-wigs-for-women-in-andheri"><Link to="/hair-wigs-for-women-in-andheri" className="nav-link">Products</Link></Nav.Link>
                {/* <Nav.Link href="#D-Shine"><HashLink to="/#D-Shine" className="nav-link"> Featured Products</HashLink></Nav.Link> */}
              
                
               
                {/* <Nav.Link href="#Gallery"><Link to="/Gallery" className="nav-link">Gallery</Link></Nav.Link> */}
                <Nav.Link href="/hair-wigs-for-men-in-andheri" className=""><Link to="/hair-wigs-for-men-in-andheri" className="nav-link"> Contact Us</Link></Nav.Link>
                
               
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  
  )
}

export default Navbar1;