import  { React, useLayoutEffect } from 'react'
import '../css/Footer.css';
import logo from '../images/logo.png';
import { Link } from "react-router-dom";
const Footer = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <div>
       <div id="contact2" className="mad_section with_bg_2">
          <div className="container">
            <div className="mad_contact_items mad_flex_list">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="mad_contact_item">
                    <div className="madIcon">
                   {/* <i className="fa fa-map-marker"></i> */}
                    </div>
                    <h4 className="lnr lnr-location">Contact Us</h4>
                    <p>
                    Vibrant look , 426, 4th floor, mohid height, mhada signal, rto lane, lokhandwala road, suresh nagar, andheri west, mumbai-400053
                    </p>
                   
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="mad_contact_item">
                  <div className="madIcon">
                   {/* <i className="fa fa-clock-o"></i> */}
                    </div>
                    <h4 className="lnr lnr-clock">Opening Times</h4>
                    <p>
                      We will available  <br />all days of week
                    </p>
                    <p>
                     All weekdays <br />
                       11:00 am - 8:00 pm 
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="mad_contact_item">
                  <div className="madIcon">
                   {/* <i className="fa fa-file-text"></i> */}
                    </div>
                    <h4 className="lnr lnr-calendar-full">Apointment</h4>
                    <p>
                      Book your appointment with us easily by call or by visiting our place at Andheri(W)
                    </p>
                    <a href="#">deepaksverma5@gmail.com</a><br/>
                    <a href="tel:+91 996 703 6217">Phone: +91 996 703 6217</a>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="mad_contact_item">
                  <div className="madIcon">
                   {/* <i className="fa fa-share-alt"></i> */}
                    </div>
                    <h4 className="lnr lnr-earth">Stay Connected</h4>
                    <p>
                      Get Update with us through  <br /> social media 
                      
                    </p>
                    <div className="social_icon_list">
                      <ul className="">
                        <li>
                        <a href="https://www.facebook.com/profile.php?id=100063615834077" className="soc_icon icon-facebook" ><i className="fa fa-facebook"></i> </a>
                        </li>
                        <li>
                        <a href="https://www.instagram.com/vibrantlookcompletehairsolutio/" className="soc_icon icon-instagram" ><i className="fa fa-instagram"> </i></a>
                        </li>
                        <li>
                        <a href="https://goo.gl/maps/Rh6BBtLL7ParzXMPA" className="soc_icon icon-google" >    <i className="fa fa-google"> </i></a>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer id="footer" className="footer">
  {/* Footer section */}
  <div className="mad_top_footer">
    <div className="v_align_center_blocks">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-12">
            <div className="mad_nav_list d-flex justify-content-center align-items-center pt-lg-5">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                <Link to="/non-surgical-hair-replacement">About Us</Link>
                </li>
                <li>
                <Link to="/hair-wigs-for-women-in-andheri">Products</Link>
                </li>
                <li>
                <Link to="/hair-wigs-for-men-in-andheri">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-12 d-flex justify-content-center">
            <div className="mad_logo_container">
            <a href="#" >
              <img src={logo} alt="" />
            </a>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="align_right d-flex justify-content-center align-items-center flex-column pt-lg-5">
              <p className="text-white">© 2023 Vibrant look</p>
              <p className="text-white">Designed by Shree Krishna Digital Marketing</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

    </div>
  )
}

export default Footer