import React from "react";
import "../css/About.css";
import saloon from "../images/work2.jpeg";
import testi from "../images/565x520_img.jpg";
import c1 from "../images/products/wig1.jpg";
import c2 from "../images/products/wig2.jpg";
import bg from "../images/work1.jpg";
import c3 from "../images/hair-clips.jpg";
import c4 from "../images/products/wig3.jpg";
import c5 from "../images/products/wig11.jpg";
import c6 from "../images/products/topper.jpg";
import why2 from "../images/why1.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { MetaTags } from "react-meta-tags";
// import { Autoplay } from "swiper";
import Footer from "../component/Footer.js";
const About = () => {
  return (
    <div>
         <MetaTags>
      <title>Non Surgical hair Replacement In Andheri | Vibrant Look</title>
        <meta title="Non Surgical hair Replacement In Andheri | Vibrant Look" />
        <meta
          name="description"
          content=" Experience a Vibrant Look with Our Non-Surgical Hair Replacement Solutions in Andheri. Reclaim Your Confidence!"
        />
        <meta
          name="keywords"
          content="hair wigs clinic in andheri ,non surgical hair replacement,hair wigs for women in andheri,hair wigs for men in andheri,hair extension in andheri ,hair wigs in andheri,hair extensions for women in andheri ,hair patch in andheri "
        />
        <link rel="canonical" href="https://vibrantlookhair.com/non-surgical-hair-replacement" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Non Surgical hair Replacement In Andheri | Vibrant Look" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vibrantlookhair.com/"/>
        <meta property="og:description" content=" Experience a Vibrant Look with Our Non-Surgical Hair Replacement Solutions in Andheri. Reclaim Your Confidence!" />
        <meta property="og:image" content="https://vibrantlookhair.com/static/media/logo.00f17de29cc5f7bfe7b7.png" />       
      </MetaTags>
      <div className="mad_page_title paralax_image_bg1 v_align_center_blocks">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
          
              <div className="mad_breadcrumbs">
              <h1 style={{color:"white"}}>Non Surgical hair Replacement In Andheri </h1>
                <nav>
                  <a href="#">Home</a>
                  <a href="#">Pages</a>About Us Page
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

     
              {/* <div className="col-sm-12">
          
          <figure className="mad_item_offset_2 salonpic">
            <img src={saloon} alt="" />
          </figure> */}
        
{/* -----------------------------about company------------------------- */}
<section className="section section-sm bg-default">
        <div className="container">
          <div className="row row-30 align-items-lg-center flex-lg-row-reverse">
            <div
              className="col-lg-6 wow fadeInSmall"
              data-wow-delay=".3s"
              style={{
                visibility: "visible",
                animationDelay: "0.3s",
                animationName: "fadeInSmall",
              }}
            >
              <div className="img-custom-position"  id="useprod">
                <img src={why2} alt="" width={608} height={477} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="product-about">
                <h1
                  className="fw-bold wow fadeInSmall"
                  data-wow-delay=".1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInSmall",
                  }}
                >
                 Story of  10 Years of work experience 
                </h1>
                <div
                  className="divider wow fadeInSmall"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInSmall",
                  }}
                />
                <p
                  className="wow fadeInSmall"
                  data-wow-delay=".3s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.3s",
                    animationName: "fadeInSmall",
                  }}
                >
                 At vibrant look in Mumbai, we build every product on the firm belief that beautiful and healthy hair should be the standard, not the exception. This is the “why” behind our launch of the Vibrant look . We are laying the foundation for people to have incredible hair days, every day with the wigs which is easy to maintain.   </p>
                <p
                  className="wow fadeInSmall"
                  data-wow-delay=".3s"
                
                >
                Our Premium Extensions ,artificial hairs and hair patchs are The Rarest , Most Exclusive Hair Globally Hand Picked & Sourced By Us Directly .
    </p>
               
            
              </div>
            </div>
          </div>
        </div>
      </section>

{/* -----------------------------------non surgical----------- */}
<section className="mad_item_offset ">

<div className="container">
            <div className="row pt-2">
            
              <div className="col-lg-7 col-12 ">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                  <div className="prodImg">
                        <img src={c1} alt="" />
                       
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                  <div className="prodImg">
                        <img src={c2} alt="" />
                       
                      </div>
                  </div>

                </div>
                <div className="row mt-lg-5 mt-2">
                  <div className="col-lg-6 col-md-6 col-12 mt-2">
                  <div className="prodImg">
                        <img src={bg} alt="" />
                        
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-2">
                  <div className="prodImg">
                        <img src={saloon} alt="" />
                       
                      </div>
                  </div>
                </div>
              
              </div>
              <div className="col-lg-5 col-12 pt-2">
                <h4>Non-Surgical Hair Replacement for Men & Women</h4>
                <p className="mad_no_space">
                We are experts in our field, and specialize in helping men and women with all forms of hair loss with a variety of non surgical hair replacement.The advantages of this procedure are enormous if done right- Offers an unmatchable density of hair– No other procedure can give you the density of hair that hair replacement can.Non Surgical Procedure– In case you are scared of going under the knife, this is the procedure for you. Quickest way to get hair– 2 hours. That’s all it takes for you to go from Bald to Bold. 
                </p>
                <p>Vibrant look offers hair thinning/hair loss solution with the help of it's most natural looking hair toppers & hair extensions .We help you in getting length & Volume in an Instant . We are a one stop shop for all your hair needs .   </p>
              
              <p className="mad_no_space">
              Whatever your hair loss condition or concern, we have expert advice, and options like artificial hairs ,extensions ,wigs and hair patches to help guide, and support you every step of the way.</p>
              </div>
            </div>
            </div>
          </section>

{/* ------------------------------our products---------------- */}
<div id="content">
        <div className="mad_section_2">
          <div className="container">
            <div className="row">
     <div className="mad_item_offset">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="align_center">
                      <h2>Our Products</h2>
                      <p className="mad_item_offset_2">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nam euismod dignissim <br /> massa ut venenatis. Vivamus
                        eleifend sem id ligula sollicitudin feugiat. Sed viverra{" "}
                        <br /> erat finibus, elementum velit sit amet.
                      </p>
                      <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="prodImg">
                            <img src={c3} alt="" />
                            <div className="imgTitle">
                              <h2>Hair Clip-ons</h2>
                            </div>
                          </div>
                        </div>
                      <div className="col-lg-3 col-md-6 col-12">
                          <div className="prodImg">
                            <img src={c4} alt="" />
                            <div className="imgTitle">
                              <h2>Hair Wigs</h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="prodImg">
                            <img src={c5} alt="" />
                            <div className="imgTitle">
                              <h2>Hair Extension</h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="prodImg">
                            <img src={c6} alt="" />
                          </div>
                          <div className="imgTitle">
                            <h2>Hair topper</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              </div>
      </div>

  

              <div className="mad_section">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mad_section_container mad_testimonials">
                        <article>
                          <h3 className="mad_title_style1">Testimonials</h3>
                          <h2>Customers Says</h2>
                          {/* 
                    <!-- Carousel --> */}
                          <Swiper
                            navigation={true}
                            breakpoints={{
                              570: {
                                slidesPerView: 1,
                                spaceBetween: 70,
                              },
                              640: {
                                slidesPerView: 2,
                                spaceBetween: 50,
                              },
                              768: {
                                slidesPerView: 1,
                                spaceBetween: 40,
                              },
                              1024: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                              },
                            }}
                            modules={[Navigation]}
                            className="mySwiper1"
                          >
                           <SwiperSlide>
                      <blockquote>
                        <p>
                          It was a awesome experience to put hair wig,
                          i m very happy by their work,
                          before i m nt look good bcs of my hair loss,
                          but now i m looking much better then before,
                          everyone should try this who suffering frm hair loss....thanku u.
                        </p>
                        <div class="mad_author style2">
                          <h3 class="mad_title_style2">ShAhZaD KhAn</h3>
                          {/* <span> Founder and CEO</span> */}
                        </div>
                      </blockquote>
                    </SwiperSlide>
                    <SwiperSlide>
                      <blockquote>
                        <p>
                          Mr. Deepak is a very genuine and professional guy. He made an excellent wig for us.
                        </p>
                        <div class="mad_author style2">
                          <h3 class="mad_title_style2">Surbhi Mukhija</h3>
                          {/* <span> Founder and CEO</span> */}
                        </div>
                      </blockquote>
                    </SwiperSlide>
                    <SwiperSlide>
                      <blockquote>
                        <p>
                          It was excellent experience. Deepak was very courteous. Hair Service was nicely done. Clean and tidy place, easily locatable. Highly recommended.
                        </p>
                        <div class="mad_author style2">
                          <h3 class="mad_title_style2">sahil gulati</h3>
                          {/* <span> Founder and CEO</span> */}
                        </div>
                      </blockquote>
                    </SwiperSlide>
                    <SwiperSlide>
                      <blockquote>
                        <p>
                          I'm happy ... I'm too much satisfied with his work ...  after I use this.. I improve my confidence... excellent work... 👌👌👌👌👌 highly recommend... ⭐⭐⭐⭐⭐😊
                        </p>
                        <div class="mad_author style2">
                          <h3 class="mad_title_style2">Akash Dubey</h3>
                          {/* <span> Founder and CEO</span> */}
                        </div>
                      </blockquote>
                    </SwiperSlide>
                    <SwiperSlide>
                      <blockquote>
                        <p>
                          Well.. to put it simply
                          Mr deepak does his work with precision and utmost care that makes overall experience very nice and trustworthy. Recommended highly!
                        </p>
                        <div class="mad_author style2">
                          <h3 class="mad_title_style2">mayank sahajwani</h3>
                          {/* <span> Founder and CEO</span> */}
                        </div>
                      </blockquote>
                    </SwiperSlide>
                    <SwiperSlide>
                      <blockquote>
                        <p>
                          Excellent service! Very friendly staff. Worth the money! Thanks for the great experience.
                        </p>
                        <div class="mad_author style2">
                          <h3 class="mad_title_style2">Kirti Goenka</h3>
                          {/* <span> Founder and CEO</span> */}
                        </div>
                      </blockquote>
                    </SwiperSlide>
                    <SwiperSlide>
                      <blockquote>
                        <p>
                          The Best Place For Non Surgical Hair Replacement System & Weigs Hair Toupees Very Good Work Clean & Neet With Good Polite & Cooperative Staff At Reasonable Rates Thnx 👍Mr Deepak Bhai & Sandeep Bhai For Good Work Done...
                        </p>
                        <div class="mad_author style2">
                          <h3 class="mad_title_style2">Prashant Deshpande</h3>
                          {/* <span> Founder and CEO</span> */}
                        </div>
                      </blockquote>
                    </SwiperSlide>
                    <SwiperSlide>
                      <blockquote>
                        <p>
                          Have done here for the 1st time...I have recently shifted from hyd....the guys Deepak & Sandeep are rockstar. They are the best.
                        </p>
                        <div class="mad_author style2">
                          <h3 class="mad_title_style2">Jeet Bhattacharjee</h3>
                          {/* <span> Founder and CEO</span> */}
                        </div>
                      </blockquote>
                    </SwiperSlide>
                          </Swiper>
                        </article>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure className="section_image align_right">
                        <img src={testi} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>

           
              {/* </div> */}
            </div>
          </div>
        
      <Footer />
    </div>
  );
};

export default About;
