import React from 'react'
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './component/Home';
import About from './component/About';
// import Product from './component/Product';
import "../src/css/Responsive.css";
import Navbar1 from './component/Navbar1';
import Product from './component/Product';
import Contact from './component/Contact';
import ProductInfo from './component/ProductInfo';
import Error from './component/Error';
import ProductData from './component/ProductData';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Navbar1 />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/non-surgical-hair-replacement' element={<About />} />
          <Route path='/hair-wigs-for-women-in-andheri' element={<Product />} />
          {/* <Route path='/Faqs' element={<Faqs/>} />
         <Route path='/Doctors' element={<Doctors/>} />
         <Route path='/Contact' element={<Contact/>} /> 
         <Route path='/Services' element={<Services/>} /> */}

          <Route path='/hair-wigs-for-men-in-andheri' element={<Contact />} />
          <Route path='/ProductInfo' element={<ProductInfo />} />
          <Route path='*' element={<Error/>} />
          {
            ProductData.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<ProductInfo/>}
                  />
                </>
              )
            })
          }
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;