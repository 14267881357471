import React from 'react'
import Navbar1 from './Navbar1'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import "../css/Error.css"
const Error = () => {
  return (
    <div>

       <div className='Errorpage'>
                <div class='error'>
                    <h1 class='code' style={{color:"#9424bc"}}>404</h1>
                    <h2 class='desc' style={{marginTop:"60px", color:"white"}}>Ops... There's something wrong.</h2>
                    <Link to="/" style={{color:"white"}}> 
                        ... Back to previous page
                    </Link>
                </div>
            </div>
            <Footer/>
    </div>
  )
}

export default Error
