import React from 'react'
import c3 from "../images/hair-clips.jpg";
import c4 from "../images/hair-fixing.jpg";
import c5 from "../images/Hair-Extension.jpg";
import c6 from "../images/bf-af.jpg";
import Footer from "../component/Footer.js";
import hair1 from "../images/products/wig1.jpg";
import hair2 from "../images/products/wig2.jpg";
import hair3 from "../images/products/wig3.jpg";
import hair4 from "../images/products/wig4.jpg";
import hair5 from "../images/products/wig5.jpg";
import hair6 from "../images/products/wig6.jpg";
import hair7 from "../images/products/wig7.jpg";
import hair8 from "../images/products/wig8.jpg";
import hair9 from "../images/products/wig9.jpg";
import hair10 from "../images/products/wig10.jpg";
import hair11 from "../images/products/wig11.jpg";
import hair12 from "../images/products/wig12.jpg";
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Autoplay } from "swiper";
import ProductData from './ProductData';
import { MetaTags } from "react-meta-tags";
const Product = () => {


  return (
    <div>
 <MetaTags>
      <title></title>
        <meta title="Hair Wigs for Women in Andheri | Vibrant Look" />
        <meta
          name="description"
          content="Discover the Ultimate Elegance with Hair Wigs for Women in Andheri. Elevate Your Style!"
        />
        <meta
          name="keywords"
          content="hair wigs clinic in andheri ,non surgical hair replacement,hair wigs for women in andheri,hair wigs for men in andheri,hair extension in andheri ,hair wigs in andheri,hair extensions for women in andheri ,hair patch in andheri "
        />
        <link rel="canonical" href="https://vibrantlookhair.com/hair-wigs-for-women-in-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Hair Wigs for Women in Andheri | Vibrant Look" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vibrantlookhair.com/"/>
        <meta property="og:description" content="Discover the Ultimate Elegance with Hair Wigs for Women in Andheri. Elevate Your Style!" />
        <meta property="og:image" content="https://vibrantlookhair.com/static/media/logo.00f17de29cc5f7bfe7b7.png" />       
      </MetaTags>
      <div className="mad_page_title paralax_image_bg1 v_align_center_blocks">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">

              <div className="mad_breadcrumbs">
                <h1 style={{color:"white"}}>Hair Wigs for Women in Andheri</h1>
                <nav>
                  <a href="#">Home</a>
                  <a href="#">Pages</a>Product Page
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* -------------------------------variety wigs----------------------- */}
      <section className="mad_item_offset">

        <div class="container">
          <div className="mad_item_offset">
            <div className="row">
              <div className="col-sm-12">
                <div className="mad_section_container">
                  <h3 className="mad_separator style2">Variety WIgs</h3>
                </div>
                {/* Carousel */}
                <Swiper
                  navigation={true}
                  breakpoints={{
                    570: {
                      slidesPerView: 1,
                      spaceBetween: 70,
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 50,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                  }}
                  modules={[Navigation]}
                  className="mySwiper1"
                >
                  <SwiperSlide>
                    <div
                      className="single-service-item text-center wow fadeInUp"
                      data-wow-delay="0.5s"
                      data-wow-duration="1s"
                      data-wow-offset={0}
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.5s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="img-holder">
                        <img src={hair1} alt="Awesome" />
                        <div className="overlay-style-one">
                          <div className="box">
                            <div className="content">
                              <p>Dr. Khushal Gangwani with Piyush Sahdev</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="single-service-item text-center wow fadeInUp"
                      data-wow-delay="0.5s"
                      data-wow-duration="1s"
                      data-wow-offset={0}
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.5s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="img-holder">
                        <img src={hair1} alt="Awesome Image" />
                        <div className="overlay-style-one">
                          <div className="box">
                            <div className="content">
                              <p>Reception</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="single-service-item text-center wow fadeInUp"
                      data-wow-delay="0.5s"
                      data-wow-duration="1s"
                      data-wow-offset={0}
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.5s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="img-holder">
                        <img src={hair2} alt="Awesome Image" />
                        <div className="overlay-style-one">
                          <div className="box">
                            <div className="content">
                              <p></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="single-service-item text-center wow fadeInUp"
                      data-wow-delay="0.5s"
                      data-wow-duration="1s"
                      data-wow-offset={0}
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.5s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="img-holder">
                        <img src={hair3} alt="Awesome Image" />
                        <div className="overlay-style-one">
                          <div className="box">
                            {/* <div className="content">
                          <p>Doctor Cabin</p>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="single-service-item text-center wow fadeInUp"
                      data-wow-delay="0.5s"
                      data-wow-duration="1s"
                      data-wow-offset={0}
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.5s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="img-holder">
                        <img src={hair4} alt="Awesome Image" />
                        <div className="overlay-style-one">
                          <div className="box">
                            <div className="content">
                              {/* <p>Doctor Cabin view2</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="single-service-item text-center wow fadeInUp"
                      data-wow-delay="0.5s"
                      data-wow-duration="1s"
                      data-wow-offset={0}
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.5s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="img-holder">
                        <img src={hair5} alt="Awesome Image" />
                        <div className="overlay-style-one">
                          <div className="box">
                            {/* <div className="content">
                          <p>Machine</p>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="single-service-item text-center wow fadeInUp"
                      data-wow-delay="0.5s"
                      data-wow-duration="1s"
                      data-wow-offset={0}
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.5s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="img-holder">
                        <img src={hair6} alt="Awesome Image" />
                        <div className="overlay-style-one">
                          <div className="box">
                            {/* <div className="content">
                          <p></p>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="single-service-item text-center wow fadeInUp"
                      data-wow-delay="0.5s"
                      data-wow-duration="1s"
                      data-wow-offset={0}
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.5s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="img-holder">
                        <img src={hair7} alt="Awesome Image" />
                        <div className="overlay-style-one">
                          <div className="box">
                            {/* <div className="content">
                          <p>Machine</p>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="single-service-item text-center wow fadeInUp"
                      data-wow-delay="0.5s"
                      data-wow-duration="1s"
                      data-wow-offset={0}
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.5s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="img-holder">
                        <img src={hair8} alt="Awesome Image" />
                        <div className="overlay-style-one">
                          <div className="box">
                            {/* <div className="content">
                          <p>Machine</p>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="single-service-item text-center wow fadeInUp"
                      data-wow-delay="0.5s"
                      data-wow-duration="1s"
                      data-wow-offset={0}
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.5s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="img-holder">
                        <img src={hair9} alt="Awesome Image" />
                        <div className="overlay-style-one">
                          <div className="box">
                            {/* <div className="content">
                          <p>Machine</p>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="single-service-item text-center wow fadeInUp"
                      data-wow-delay="0.5s"
                      data-wow-duration="1s"
                      data-wow-offset={0}
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.5s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="img-holder">
                        <img src={hair10} alt="Awesome Image" />
                        <div className="overlay-style-one">
                          <div className="box">
                            {/* <div className="content">
                          <p>Machine</p>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide> <SwiperSlide>
                    <div
                      className="single-service-item text-center wow fadeInUp"
                      data-wow-delay="0.5s"
                      data-wow-duration="1s"
                      data-wow-offset={0}
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.5s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="img-holder">
                        <img src={hair11} alt="Awesome Image" />
                        <div className="overlay-style-one">
                          <div className="box">
                            {/* <div className="content">
                          <p>Machine</p>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      className="single-service-item text-center wow fadeInUp"
                      data-wow-delay="0.5s"
                      data-wow-duration="1s"
                      data-wow-offset={0}
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.5s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="img-holder">
                        <img src={hair12} alt="Awesome Image" />
                        <div className="overlay-style-one">
                          <div className="box">
                            {/* <div className="content">
                          <p>Machine</p>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mad_item_offset">
        <div className='container'>
          <div className="row">
            <div className="col-sm-12">
              <div className="align_center">
                <h2>Our Products</h2>
                <p className="mad_item_offset_2">
                  We are constantly innovating and improving our products and services,   <br />
                  so that our customers can always find the perfect hair style for them.
                </p>
                <div className="row">
                  {
                    ProductData.map((ele) => {

                      const { ProductImage01, ProductName, slug } = ele
                      return (
                        <>
                          <div className="col-lg-3 col-md-6 col-12 productpage-p">
                            <Link to={`${slug}`}>
                              <div className='Product-card-body'>
                                <div className="prodImg">
                                  <img src={ProductImage01} alt="" />
                                </div>
                                <div className="Productcard-name">
                                  <h2>{ProductName}</h2>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      )
                    })
                  }

                  {/* <div className="col-lg-3 col-md-6 col-12">
                    <div className='Product-card-body'>

                      <div className="prodImg">
                        <img src={c4} alt="" />
                      </div>
                      <div className="Productcard-name">
                        <h2>Hair Wigs</h2>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-lg-3 col-md-6 col-12">
                    <div className='Product-card-body'>

                      <div className="prodImg">
                        <img src={c5} alt="" />
                      </div>
                      <div className="Productcard-name">
                        <h2>Hair Extension</h2>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-lg-3 col-md-6 col-12">
                    <div className='Product-card-body'>

                      <div className="prodImg">
                        <img src={c6} alt="" />
                      </div>
                      <div className="Productcard-name">
                        <h2>Hair Fixing</h2>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <Footer />
    </div>
  )
}

export default Product