import c3 from "../images/hair-clips.jpg";

import c6 from "../images/products/topper.jpg";

import HairClips01 from "../images/Pro Image/Hair Clips 01.jpg";
import HairClips02 from "../images/Pro Image/Hair Clips 02.png";
import HairClips03 from "../images/Pro Image/Hair Clips 03.png";
import HairClips04 from "../images/Pro Image/Hair Clips 04.png";
import HairClips05 from "../images/Pro Image/Hair Clips 05.webp";

import HairWig01 from "../images/products/g10.jpg";
import HairWig02 from "../images/products/wig5.jpg";
import HairWig03 from "../images/products/wig2.jpg";
import HairWig04 from "../images/products/wig3.jpg";
import HairWig05 from "../images/products/wig4.jpg";

import HairExtension01 from "../images/products/wig1.jpg";
import HairExtension02 from "../images/products/wig11.jpg";
import HairExtension03 from "../images/products/wig12.jpg";
import HairExtension04 from "../images/products/wig1.jpg";


const ProductData = [
    {
        id: 1,
        title1:"Hair Extension In Andheri",
        slug:"/hair-extension-in-andheri",
        title:"Hair Extension In Andheri | Vibrant Look",
        conical:"https://vibrantlookhair.com/hair-extension-in-andheri",
        description:"Experience the Magic of Hair Extensions in Andheri. Embrace a New Level of Glamour Today!",
        ProductName: "Hair Clip-ons",
        ProductImage01: c3,
        ProductImage02: HairClips01,
        ProductImage03: HairClips02,
        ProductImage04: HairClips03,
        ProductImage05: HairClips05,
        ProductDes01: "Clip-in hair extension are hand-crafted from Indian human hair in a variety of textures to add instant volume and length in a snap. These clips allow for easy placement and seamless blend for a look that's lightweight and simple to install and remove.",
        ProductDes02: "You’ll be able to find clip in extensions that suit all natural hair textures. No matter how kinky, curly or coilyyour texture is, our clip in extensions come in  different textures that will perfectly match yours.",

    },
    {
        id: 2,
        title1:"Hair Wigs in Andheri",
        slug:"/hair-wigs-in-andheri",
        title:"Hair Wigs in Andheri | Vibrant Look",
        conical:"https://vibrantlookhair.com/hair-wigs-in-andheri",
        description:" Unlock Your Beauty Potential with Quality Hair Wigs in Andheri. Elevate Your Style Today!",
        ProductName: "Hair Wigs",
        ProductImage01: HairWig05,
        ProductImage02: HairWig01,
        ProductImage03: HairWig02,
        ProductImage04: HairWig03,
        ProductImage05: HairWig04,
        ProductDes01: "A beautiful wig shape can bring you a different mood. Transform your look with our stunning collection of wigs.",
        ProductDes02: "Discover the perfect human hair wig! We offer a wide range of styles, textures, lengths, colors and cap constructions to choose from. Our human hair wigs are made with 100% natural human hair for a realistic look that’s easy to style and long-lasting.",

    },
    {
        id: 3,
        title1:"Hair Extensions for Women in Andheri",
        slug:"/hair-extensions-for-women-in-andheri",
        title:"Hair Extensions for Women in Andheri - Vibrant Look",
        conical:"https://vibrantlookhair.com/hair-extensions-for-women-in-andheri",
        description:" Elevate Your Beauty with Luxurious Hair Extensions for Women in Andheri. Discover Glamour!",
        ProductName: "Hair Extension",
        ProductImage01:HairExtension03,
        ProductImage02: HairExtension01,
        ProductImage03: HairExtension02,
        ProductImage04: HairExtension03,
        ProductImage05: HairExtension04,
        ProductDes01: "Hair Extensions is the perfect solution for anyone looking to add length, volume, and texture to their hair in an affordable, convenient way. Our selection of hair extensions, ranges from microlinks, tape-ins, clip-ins to weft bundles.",
        ProductDes02: "Our hair extensions are made from 100% virgin hair and are made to last, which can be bleached, dyed, curled, and straightened with ease. Shop now to achieve the look of your dreams!",

    },
    {
        id: 4,
        title1:"Hair Patch in Andheri",
        slug:"/hair-patch-in-andheri",
        title:"Vibrant Look - Hair Patch in Andheri",
        conical:"https://vibrantlookhair.com/hair-patch-in-andheri ",
        description:"Experience Confidence Renewed with Our Hair Patch in Andheri. Reclaim Your Charm!",
        ProductName: "Hair Topper(Fringes)",
        ProductImage01: c6,
        ProductDes01: "Refix your hair in 2 hours. Custom made hair patches that suit your original hair’s color & texture. No Surgery, No Medicines, No Pain, No Scars, No Side Effects in our Hair Fixing methods.",
        ProductDes02: "100% Human hair patches. Get the hair style you want with our natural looking hair patches. Look Younger and Confident.",
    },
]

export default ProductData;